<template>
  <!-- <div class="grid grid-cols-1 gap-12 mt-4" v-if="item"> -->
  <div class="mt-4" v-if="item">
    <div class="flex flex-col gap-8 md:flex-row">
      <Slider :item="item" />
      <!-- info -->
      <div>
        <div v-html="item.title" class="font-semibold text-3xl mb-6 mt-1" />
        <div v-html="item.subtitle" class="font-semibold text-xl mb-6" />
        <!-- packageCode -->
        <div class="text-lg">
          <div>
            Package Code: <span class="uppercase">{{ item.packageCode }}</span>
          </div>
          <div v-html="'Room Type: ' + item.roomType.title" />
          <div v-html="'# Nights: ' + item.numberNights" />
          <div
            v-if="$store.state.selectedPartner.displayCostToOrg"
            v-html="
              'Cost to Organization: ' + '' + '$' + item.costToOrg + '.00'
            "
          />
          <div
            v-html="'Max Retail Value: ' + '' + '$' + item.maxRetail + '.00'"
          />
        </div>
        <!-- buttons -->
        <div class="mt-14 md:mt-10 gap-4 flex flex-wrap">
          <!-- print PDF -->
          <button
            @click="onPdf"
            class="
              uppercase
              bg-blue-600
              text-blue-50
              hover:bg-blue-700
              font-semibold
              cursor-pointer
              text-sm
              tracking-wide
              transition
              rounded-full
              px-4
              py-2
              space-x-2
              inline-flex
              items-center
              justify-between
            "
          >
            <span>Print Package PDF</span>
            <i class="mdi mdi-file-pdf-box text-xl"></i>
          </button>
          <!-- add to wishlist -->
          <!-- user -->
          <button
            v-if="$store.state.user"
            @click="onWishList(item)"
            class="
              uppercase
              bg-pink-600
              text-pink-50
              hover:bg-pink-700
              font-semibold
              cursor-pointer
              text-sm
              tracking-wide
              transition
              rounded-full
              px-4
              py-2
              space-x-2
              inline-flex
              items-center
              justify-between
            "
          >
            <span>{{
              $store.state.userWishList.length &&
              $store.state.userWishList.some((i) => i == item.packageCode)
                ? "Remove from Wish List"
                : "Add to Wish List"
            }}</span>
            <i
              class="mdi text-xl"
              :class="
                $store.state.userWishList.length &&
                $store.state.userWishList.some((i) => i == item.packageCode)
                  ? 'mdi-heart'
                  : 'mdi-heart-outline'
              "
            ></i>
          </button>
          <!-- !user -->
          <button
            @click="
              $store.state.authModal.display = true;
              $store.state.authModal.action = 'signup';
            "
            v-else
            class="
              uppercase
              bg-pink-600
              text-pink-50
              hover:bg-pink-700
              font-semibold
              cursor-pointer
              text-sm
              tracking-wide
              transition
              rounded-full
              px-4
              py-2
              space-x-2
              inline-flex
              items-center
              justify-between
            "
          >
            <span>Add to Wish List</span>
            <i class="mdi mdi-heart-outline text-xl"></i>
          </button>
          <!-- watch video -->
          <button
            @click="
              $store.state.youTubeModal = true;
              $store.state.youTubeItem = item;
            "
            class="
              uppercase
              bg-red-600
              text-red-50
              hover:bg-red-700
              font-semibold
              cursor-pointer
              text-sm
              tracking-wide
              transition
              rounded-full
              px-4
              py-2
              space-x-2
              inline-flex
              items-center
              justify-between
            "
          >
            <span>Watch Package Video</span>
            <i class="mdi mdi-youtube text-xl"></i>
          </button>
        </div>
      </div>
    </div>

    <!-- blurbs -->
    <div
      class="col-span-full grid px-4 mt-4"
      :class="
        item.blurbs.length == 3
          ? 'grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-14'
          : 'grid-cols-1 md:grid-cols-2 gap-14'
      "
    >
      <div class="col-span-full"><hr /></div>
      <div v-for="(item, index) in item.blurbs" :key="index">
        <!-- title -->
        <div class="flex items-center mb-2">
          <span
            class="mr-3 mdi text-blue-700 text-2xl"
            :class="item.icon"
          ></span>
          <span v-html="item.title" class="font-semibold text-xl" />
        </div>
        <!-- content -->
        <div v-html="item.content" class="blurbList" />
      </div>
    </div>
    <!-- terms -->
    <div class="col-span-2 space-y-8 px-4 mt-10">
      <hr />
      <h3 class="font-semibold text-2xl">Terms &amp; Conditions</h3>
      <div v-html="item.terms.content" v-if="item.terms" />
    </div>
    <!-- best sellers -->
    <div class="col-span-2 space-y-10">
      <hr />
      <div class="pageTitle">
        <h1>Our Best Selling Packages</h1>
        <h3>A great starting point for any event</h3>
      </div>
      <RetailPackages :items="$store.state.retailPackages" />
    </div>
  </div>
  <!-- loading -->
  <div v-else>
    <Loading />
  </div>
</template>

<script>
import RetailPackages from "@/components/RetailPackages";
import Slider from "@/components/PackageSlider";
import Loading from "@/components/Loading";
import { firebase, db } from "@/firebase";
import { useToast } from "vue-toastification";
import NProgress from "nprogress";
export default {
  components: {
    RetailPackages,
    Slider,
    Loading,
  },
  data: () => ({
    toast: useToast(),
    item: "",
  }),
  methods: {
    onPdf() {
      if (this.$store.state.user) {
        window.open(this.item.pdfDownloadUrl, "_blank").focus();
      } else {
        this.$store.state.authModal.display = true;
        this.$store.state.authModal.action = "signup";
      }
    },
    onWishList(item) {
      if (!item) return;
      if (!this.$store.state.userWishList.length) return;
      // check if item is already in wishList
      let isInWishList = this.$store.state.userWishList.some(
        (i) => i == item.packageCode
      );
      if (isInWishList) {
        // if in wishList => remove
        NProgress.start();
        db.collection("users")
          .doc(this.$store.state.userAccountData.id)
          .update({
            wishList: firebase.firestore.FieldValue.arrayRemove(
              item.packageCode
            ),
          })
          .then(() => {
            NProgress.done();
            this.toast.success("Removed from Wish List");
          });
      } else {
        // not in wishList => add to wishList
        NProgress.start();
        db.collection("users")
          .doc(this.$store.state.userAccountData.id)
          .update({
            wishList: firebase.firestore.FieldValue.arrayUnion(
              item.packageCode
            ),
          })
          .then(() => {
            NProgress.done();
            this.toast.success("Added to Wish List");
          });
      }
    },
  },
  computed: {
    roomType() {
      return this.item.roomType;
    },
  },

  watch: {
    "$store.state.retailPackages": {
      immediate: true,
      deep: true,
      handler(items) {
        if (!this.$route.params.packageCode)
          return this.$router.push(
            `/${this.$store.state.selectedPartner.id}/404`
          );
        if (!items.length) return;
        if (this.item != "") return;
        let item = items.find(
          (i) => i.packageCode == this.$route.params.packageCode
        );
        if (item) return (this.item = item);
        return this.$router.push(
          `/${this.$store.state.selectedPartner.id}/404`
        );
      },
    },
    "$route.params.packageCode"(newVal, oldVal) {
      // if user clicks on package from best sellers at bottom of page
      // will update "item" in state
      if (newVal != oldVal) {
        let items = this.$store.state.retailPackages;
        let item = items.find((i) => i.packageCode == newVal);
        this.item = item;
        this.show = 0;
      }
    },
  },
};
</script>

<style>
.blurbList ul li {
  @apply list-disc;
}
.list-disc {
  list-style-type: disc !important;
}
</style>