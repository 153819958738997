<template>
  <!-- package slider -->
  <!-- uses v-for to iterate over the package (item).images -->
  <div
    class="
      h-72
      sm:h-96
      rounded-md
      shadow
      overflow-hidden
      relative
      text-white
      max-w-lg
    "
  >
    <!-- slides -->
    <transition-group name="fade" tag="div" class="h-full w-full">
      <img
        class="object-cover w-full h-full"
        v-for="(item, index) in item.images"
        :key="index"
        :src="item.downloadUrl"
        v-show="show == index"
      />
    </transition-group>
    <!-- arrows -->
    <!-- left -->
    <div class="absolute left-1 sm:left-6 inset-y-1/2" @click="prev">
      <div
        class="
          rounded-full
          bg-blue-500
          hover:bg-blue-600
          transition
          cursor-pointer
          w-9
          h-9
          flex
          items-center
          justify-center
          text-center
        "
      >
        <i class="mdi mdi-chevron-left text-3xl"></i>
      </div>
    </div>
    <!-- right -->
    <div class="absolute right-1 sm:right-6 inset-y-1/2" @click="next">
      <div
        class="
          rounded-full
          bg-blue-500
          hover:bg-blue-600
          transition
          cursor-pointer
          w-9
          h-9
          flex
          items-center
          justify-center
          text-center
        "
      >
        <i class="mdi mdi-chevron-right text-3xl"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["item"],
  data: () => ({
    show: 0,
  }),
  computed: {
    total() {
      return this.item.images.length - 1;
    },
  },
  methods: {
    prev() {
      if (this.show == 0) return (this.show = this.total);
      this.show = this.show - 1;
    },
    next() {
      if (this.show == this.total) return (this.show = 0);
      this.show = this.show + 1;
    },
  },
};
</script>
